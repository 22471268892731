import React, { useState, useEffect } from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';
import { TeacherInfo } from '../../model/TeacherInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { loadSvg } from '../../utils/loadSvg';

interface Props {
  goTo: (component: ProfileComponent) => void;
}

const ChangeSubject: React.FC<Props> = ({ goTo }) => {
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo | null>(null);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [images, setImages] = React.useState<{ [key: string]: string }>({});

  // Funzione per caricare le immagini delle materie
  const loadSubjectImages = async (subjectsData: any[]) => {
    const newImages: { [key: string]: string } = {};
    for (const subject of subjectsData) {
      try {
        newImages[subject.subject.name] = await loadSvg(subject.subject.name);
      } catch (error) {
        console.error(`Errore nel caricamento dell'immagine per ${subject.subject.name}:`, error);
      }
    }
    setImages(newImages);
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/teacher');
        const teacher: TeacherInfo = response.data.user;
        setTeacherInfo(teacher);
        await loadSubjectImages(teacher.subjects);
      } catch (error) {
        console.error(error);
        setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherData();
  }, [setLoading, setError]);

  if (!teacherInfo) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="w-full h-full p-2 flex flex-col justify-center">
      <div></div>
      <div className="w-full p-4 grid  grid-cols-2 md:grid-cols-4 gap-4 h-full overflow-y-auto">
        {teacherInfo.subjects && teacherInfo.subjects.length > 0 ? (
          teacherInfo.subjects.map((subjectInfo, index) => (
            <div
              key={index}
              className="p-4 border rounded-lg bg-gray-50 shadow-sm h-32 w-32 flex flex-col items-center justify-center">
              <img src={images[subjectInfo.subject.name]} alt={subjectInfo.subject.name} className="w-16 h-16" />
              <h3 className="font-semibold">{subjectInfo.subject.name}</h3>
              {/* {subjectInfo.schools ? (
                Object.entries(subjectInfo.schools).map(([levelName, schoolTypes]) => (
                  <div key={levelName} className="ml-4 mb-4">
                    <h4 className="text-lg font-medium mb-2">Livello: {levelName}</h4>
                    {Object.entries(schoolTypes).map(([schoolTypeName, schools]) => (
                      <div key={schoolTypeName} className="ml-4 mb-4">
                        <h5 className="text-md font-medium mb-1">Tipo di Scuola: {schoolTypeName}</h5>
                        {schools.map((school, schoolIdx) => (
                          <div key={schoolIdx} className="ml-4 mb-2">
                            <p>
                              <strong>Nome:</strong> {school.name}
                            </p>
                            <p>
                              <strong>Livello:</strong> {school.level}
                            </p>
                            <p>
                              <strong>Classi:</strong> {school.classes.join(', ')}
                            </p>
                            <p>
                              <strong>Classi Insegnate:</strong> {school.teached_classes.join(', ')}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>Nessuna scuola associata a questa materia.</p>
              )} */}
            </div>
          ))
        ) : (
          <p>Nessuna materia trovata per questo insegnante.</p>
        )}
      </div>
    </div>
  );
};

export default ChangeSubject;
