import React, { useEffect } from 'react';
import { EventInput } from '@fullcalendar/core';
import { use } from 'i18next';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
import { useError } from '../../context/ErrorContext';
import { cl } from '@fullcalendar/core/internal-common';

interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
}

const EditFamilyLesson: React.FC<Props> = ({ selectedEvent, closeModal }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();

  if (!selectedEvent || !selectedEvent.extendedProps) {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
        <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
          <div className="flex justify-between">
            <h1 className="text-fpcred font-bold text-xl">Errore</h1>
            <button onClick={closeModal}>
              <p className="text-fpcred font-bold text-xl">X</p>
            </button>
          </div>
          <div className="flex flex-1 flex-col justify-center items-center">
            <p className="text-red-500">Errore nel caricare i dettagli della lezione.</p>
          </div>
        </div>
      </div>
    );
  }

  const { teacher, subject } = selectedEvent.extendedProps;
  // Utilizziamo selectedEvent.start, che è un oggetto Date
  const startDate = selectedEvent.start as Date;

  // Otteniamo solo la data in formato locale
  const dateOnly = startDate.toLocaleDateString();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await api.delete(`lesson/${selectedEvent.id}`);
      closeModal(); // Chiudi il modal dopo l'eliminazione
    } catch (error) {
      closeModal(); 
      console.error("Errore durante l'eliminazione della lezione", error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
      <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
        <div className="flex justify-between">
          <h1 className="text-fpcred font-bold text-xl">Informazioni sulla lezione</h1>
          <button onClick={closeModal}>
            <p className="text-fpcred font-bold text-xl">X</p>
          </button>
        </div>
        <div className="flex flex-1 flex-col">
          <p className="font-bold ">{dateOnly}</p>
          <p>Figlio: {selectedEvent.title}</p>
          <p>
            Insegnante: {teacher.first_name} {teacher.last_name}
          </p>
          <p>Materia: {subject.name}</p>
        </div>
        <div className="flex justify-center gap-4">
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2">Modifica</button>
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2" onClick={handleDelete}>Cancella</button>
        </div>
      </div>
    </div>
  );
};

export default EditFamilyLesson;
