import React, { useEffect, useState } from 'react';
import { ReactComponent as X } from '../../ui/x.svg';

interface ModalProps {
  open: boolean;
  title?: string;
  children: React.ReactNode;
  closeModal: () => void;
}

const Modal = ({ open, title, children, closeModal }: ModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (open) {
      setShowModal(true);
      setTimeout(() => setVisible(true), 10);
    } else {
      setVisible(false);
      const timer = setTimeout(() => setShowModal(false), 500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <>
      {showModal && (
        <div className={`z-50 fixed inset-0 flex items-end bg-black bg-opacity-50 transition-opacity duration-500 ${visible ? 'opacity-100' : 'opacity-0'}`}>
          <div className={`w-full bg-white rounded-t-lg h-[95%] transition-transform duration-500 ${visible ? 'translate-y-0' : 'translate-y-full'}`}>
            <div className="flex justify-between items-center pt-2 pr-4">
              <h3 className="text-lg font-semibold">{title}</h3>
              < button onClick={closeModal} className="cursor-pointer text-3xl text-fpcred font-bold"> <p>X</p> </button>
            </div>
            <div className="overflow-auto p-4 h-[95%]">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
